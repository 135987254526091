<template>
  <b-card-code
    no-body
    title="Bank"
  >
    <div class="dt_adv_search ml-1 mr-1">
      <div class="row">
        <div class="col-md-12">
          <AddBank
            :is-loading="isLoading"
            :edit-id="editId"
            :validations="validations"
            :create-item="createItem"
            :update-item="updateItem"
            :clean-up-form="cleanUpForm"
            :form-payload="formPayload"
            :icon-bank="iconBank"
            @payloadBank="getPayload($event)"
          />
        </div>
      </div>
    </div>
    <Banks
      :result="result"
      :is-loading="isLoading"
      :get-data="getData"
      :edit-item="editItem"
      :delete-item="deleteItem"
    />
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import Vue from 'vue'
import VuejsDialog from 'vuejs-dialog'
import 'vuejs-dialog/dist/vuejs-dialog.min.css'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import AddBank from '@/components/Bank/AddBank.vue'
import Banks from '@/components/Bank/Banks.vue'

Vue.use(VuejsDialog)

export default {
  title() {
    return `List Bank`
  },
  components: {
    BCardCode,
    AddBank,
    Banks,
  },
  watch: {
    filter: {
      handler: _.debounce(function (value) {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      result: {},
      isLoading: false,
      validations: {},
      formPayload: Object,
      editId: null,
      categoryName: '',
      parent: {},
      detail: {},
      iconBank: null,
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getPayload(value) {
      if (value) {
        this.formPayload = value
      }
    },
    cleanUpForm() {
      this.editId = null
      this.iconBank = null
      this.formPayload = {
        bank_name: '',
        account_number: '',
        account_name: '',
      }
      this.validations = ''
    },
    createItem() {
      this.getPayload()
      const form = this.preparePayload()
      this.isLoading = true

      this.$http.post('/admin/banks', form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-bank-modal')
          this.getData()
          successNotification(this, 'Success', 'Bank berhasil di buat')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
          this.isLoading = false
        })
    },
    updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$http.post(`/admin/banks/${this.editId}`, form, {
        headers: { 'content-type': 'multipart/form-data' },
      })
        .then(response => {
          this.$bvModal.hide('form-bank-modal')
          this.getData()
          successNotification(this, 'Success', 'Bank berhasil di ubah!')
          this.cleanUpForm()
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    preparePayload() {
      const form = new FormData()
      for (const key in this.formPayload) {
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
      }

      return form
    },
    editItem(item) {
      this.cleanUpForm()
      this.editId = item.uuid
      this.iconBank = item.logo
      this.formPayload = {
        bank_name: item.bank_name,
        account_number: item.account_number,
        account_name: item.account_name,
      }
      this.$bvModal.show('form-bank-modal')
    },
    deleteItem(id) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this bank?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`admin/banks/${id}`)
            .then(response => {
              this.getData(this.currentPage)
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Kupon berhasil di hapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    getData(page = 1) {
      this.isLoading = true

      this.$http.get('/admin/banks', {
        params: {
          page,
        },
      })
        .then(response => {
          this.result = response.data.data
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
