<template>
  <div>
    <b-button
      v-if="checkPermission('create bank')"
      v-b-modal.form-bank-modal
      class="btn btn-info btn-sm mb-1"
      data-toggle="tooltip"
      data-placement="top"
      title="Tambah Bank"
      @click="cleanUpForm"
    >
      <feather-icon
        icon="PlusCircleIcon"
      />
      Tambah Bank
    </b-button>

    <b-modal
      id="form-bank-modal"
      no-close-on-backdrop
      size="lg"
      title="Tambah Bank"
    >
      <div class="form">
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Nama Bank:</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayloadBank.bank_name"
                  :state="validationsBank.bank_name ? false:null"
                  placeholder="Nama bank"
                />
                <small
                  v-for="(validation, index) in validationsBank.bank_name"
                  v-if="validationsBank.bank_name"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Nomor Rekening:</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayloadBank.account_number"
                  :state="validationsBank.account_number ? false:null"
                  placeholder="Nomor rekening"
                />
                <small
                  v-for="(validation, index) in validationsBank.account_number"
                  v-if="validationsBank.account_number"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Atas Nama Rekening:</label>
              <validation-provider>
                <b-form-input
                  v-model="formPayloadBank.account_name"
                  :state="validationsBank.account_name ? false:null"
                  placeholder="Atas nama rekening"
                />
                <small
                  v-for="(validation, index) in validationsBank.account_name"
                  v-if="validationsBank.account_name"
                  :key="`errorName${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group">
              <label for="">Logo Bank</label><br>
              <validation-provider>
                <b-form-file
                  placeholder="Pilih file atau letakkan di sini..."
                  drop-placeholder="Drop file here..."
                  :state="validationsBank.icon ? false:null"
                  @change="changePhoto($event)"
                />
                <small
                  v-for="(validation, index) in validationsBank.icon"
                  v-if="validationsBank.icon"
                  :key="`errorIcon${index}`"
                  class="text-danger"
                >{{ validation }}</small>
              </validation-provider>
            </div>
          </div>
          <div
            v-if="iconBank"
            class="col-lg-6"
          >
            <div class="form-group">
              <label
                for=""
                class="mb-1"
              >Logo Bank Sebelumnya</label><br>
              <b-img
                :src="iconBank"
                width="70"
              />
            </div>
          </div>
        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoadingBank">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoadingBank"
          @click="editId == null ? createItem() : updateItem()"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  BButton, BSpinner, BFormInput, BFormFile, BImg,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BButton,
    BSpinner,
    ValidationProvider,
    BFormInput,
    BFormFile,
    BImg,
  },
  /* eslint-disable vue/require-default-prop */
  props: {
    formPayload: {
      type: '',
    },
    isLoading: {
      type: Boolean,
    },
    editId: {
      type: String,
    },
    iconBank: {
      type: String,
    },
    createItem: {
      type: Function,
    },
    updateItem: {
      type: Function,
    },
    validations: {
      type: '',
    },
    cleanUpForm: {
      type: Function,
    },
    parent: {
      type: Object,
    },
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      isLoadingBank: false,
      formPayloadBank: {
        bank_name: '',
        account_number: '',
        account_name: '',
      },
      validationsBank: '',
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingBank = value
    },
    validations(value) {
      this.validationsBank = value
    },
    formPayload: {
      handler(value) {
        this.formPayloadBank = value
        if (this.editId == null) {
          this.formPayloadBank.icon = value.icon
        }
      },
      deep: true,
    },
    formPayloadBank: {
      handler(value) {
        this.$emit('payloadBank', value)
      },
      deep: true,
    },
  },
  methods: {
    changePhoto(event) {
      this.formPayloadBank.logo = event.target.files[0] ? event.target.files[0] : event
    },
  },
}
</script>
